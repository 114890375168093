import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys,
);

export const switchStyle = defineMultiStyleConfig({
  baseStyle: {
    container: {
      whiteSpace: 'nowrap',
    },
    label: {
      fontWeight: 400,
      whiteSpace: 'nowrap',
    },
    track: {
      w: '24px',
      h: '10px',
      bgColor: 'gray.100',
      alignItems: 'center',
      _checked: {
        bgColor: 'blue.100',
      },
    },
    thumb: {
      w: '10px',
      h: '10px',
      bgColor: 'gray.400',
      _checked: {
        bgColor: 'blue.500',
      },
    },
  },
});
