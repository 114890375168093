import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tagAnatomy.keys,
);

export const tagStyle = defineMultiStyleConfig({
  // baseStyle: {
  //   container: {},
  //   label: {},
  //   closeButton: {},
  // },
  variants: {
    subtle: {
      container: {
        gray: {
          bgColor: 'gray.200',
          color: 'gray.500',
        },
        blue: {
          bgColor: 'blue.100',
          color: 'blue.500',
        },
        green: {
          bgColor: 'green.100',
          color: 'green.500',
        },
        red: {
          bgColor: 'red.100',
          color: 'red.500',
        },
        cyan: {
          bgColor: 'cyan.100',
          color: 'cyan.500',
        },
        purple: {
          bgColor: 'purple.100',
          color: 'purple.500',
        },
        pink: {
          bgColor: 'pink.100',
          color: 'pink.500',
        },
        orange: {
          bgColor: 'orange.100',
          color: 'orange.500',
        },
        yellow: {
          bgColor: 'yellow.100',
          color: 'yellow.500',
        },
      },
    },
    solid: {
      container: {
        blue: {
          color: 'white',
          bgColor: 'blue.500',
        },
      },
    },
    outline: {
      container: {
        gray: {
          shadow: `0 0 0 1px ${'gray.200'}`,
          color: 'gray.400',
        },
        blue: {
          color: 'blue.500',
          shadow: `0 0 0 1px ${'blue.200'}`,
        },
      },
    },
  },
  sizes: {
    sm: {
      container: {
        borderRadius: 'xl',
        fontSize: 'xs',
        px: '8px',
        minH: '14px',
      },
    },
    md: {
      container: {
        borderRadius: 'xl',
        fontSize: 'sm',
        px: '12px',
        minH: '20px',
      },
    },
    filterTag: {
      container: {
        borderRadius: 'xl',
        fontSize: 'sm',
        px: '12px',
        h: '28px',
      },
    },
  },
  defaultProps: {
    variant: 'subtle',
    size: 'md',
  },
});
