import { defineStyleConfig } from '@chakra-ui/react';

export const buttonStyle = defineStyleConfig({
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
  baseStyle: {
    lineHeight: 'normal',
  },
  variants: {
    primary: {
      bgColor: 'blue.500',
      color: 'white',
      _disabled: {
        bgColor: 'gray.100',
        color: 'gray.300',
      },
      _hover: {
        bgColor: 'blue.400',
        color: 'white',
        _disabled: {
          bgColor: 'gray.100',
          color: 'gray.300',
        },
      },
    },
    danger: {
      bgColor: 'red.500',
      color: 'white',
      _disabled: {
        bgColor: 'gray.100',
        color: 'gray.300',
      },
      _hover: {
        bgColor: 'red.400',
        color: 'white',
        _disabled: {
          bgColor: 'gray.100',
          color: 'gray.300',
        },
      },
    },
    secondary: {
      bgColor: 'white',
      color: 'blue.500',
      borderColor: 'blue.500',
      borderWidth: '1px',
      _disabled: {
        bgColor: 'gray.100',
        color: 'gray.300',
        borderColor: 'gray.100',
        svg: {
          fill: 'gray.300',
        },
      },
      _hover: {
        bgColor: 'blue.50',
        color: 'blue.400',
        svg: {
          fill: 'blue.400',
        },
        _disabled: {
          bgColor: 'gray.100',
          color: 'gray.300',
          borderColor: 'gray.100',
          svg: {
            fill: 'gray.300',
          },
        },
      },
    },
    tertiary: {
      bgColor: 'blue.100',
      color: 'blue.500',
      _disabled: {
        bgColor: 'gray.50',
        color: 'gray.300',
      },
      _hover: {
        bgColor: 'blue.100',
        color: 'blue.400',
        _disabled: {
          bgColor: 'gray.50',
          color: 'gray.300',
        },
      },
    },
    text: {
      bgColor: 'transparent',
      color: 'blue.500',
      _disabled: {
        bgColor: 'gray.100',
        color: 'gray.300',
      },
    },
    negative: {
      bgColor: 'gray.200',
      color: 'gray.500',
      _disabled: {
        bgColor: 'gray.100',
        color: 'gray.300',
      },
      _hover: {
        bgColor: 'gray.100',
        color: 'gray.500',
        _disabled: {
          bgColor: 'gray.100',
          color: 'gray.300',
        },
      },
    },
    rounded: {
      bgColor: 'blue.500',
      color: 'white',
      borderColor: 'gray.200',
      borderWidth: '1px',
      borderRadius: '15px',
      _hover: {
        bgColor: 'blue.50',
        color: 'black',
        svg: {
          fill: 'blue.500',
        },
      },
    },
    roundedOutline: {
      bgColor: 'white',
      color: 'black',
      borderColor: 'gray.200',
      borderWidth: '1px',
      borderRadius: '15px',
      _hover: {
        bgColor: 'blue.50',
      },
    },
  },
  sizes: {
    xs: {
      h: '28px',
      p: '5px 12px',
      borderRadius: '4px',
      fontSize: 'sm',
      fontWeight: 'normal',
      lineHeight: '17px',
    },
    sm: {
      h: '30px',
      p: '5px 12px',
      borderRadius: '4px',
      fontSize: 'md',
      fontWeight: 'normal',
      lineHeight: '20px',
    },
    md: {
      h: '36px',
      p: '8px 16px',
      borderRadius: '4px',
      fontSize: 'md',
      fontWeight: 'bold',
      lineHeight: '20px',
    },
  },
});
