import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tableAnatomy.keys,
);

export const tableStyle = defineMultiStyleConfig({
  defaultProps: {
    variant: 'simple',
  },
  variants: {
    simple: {
      table: {
        borderCollapse: 'separate',
        borderSpacing: '0 1px',
      },
      thead: {
        border: 'none',
        height: '34px',
        _after: {
          content: '""',
          height: '3px',
          display: 'block',
        },
      },
      tbody: {
        bgColor: 'white',
      },
      tfoot: {},
      caption: {},
      tr: {},
      th: {
        bgColor: 'gray.100',
        color: 'gray.500',
      },
      td: {
        bgColor: 'white',
        border: 'none',
      },
    },
    outline: {
      table: {},
      thead: {
        bgColor: 'white',
        borderBottom: '1px solid',
        borderColor: 'gray.200',
      },
      tbody: {
        bgColor: 'white',
      },
      tfoot: {},
      caption: {},
      tr: {},
      th: {
        color: 'gray.500',
      },
      td: {
        borderBottom: '1px solid',
        borderColor: 'gray.200',
      },
    },
  },
  sizes: {
    md: {
      th: {
        span: {
          fontSize: 'sm',
        },
        fontSize: 'sm',
        fontWeight: 'normal',
        lineHeight: '18px',
        px: 3,
        py: 2,
      },
      td: {
        fontSize: 'sm',
        lineHeight: '18px',
        p: 3,
      },
    },
  },
});
