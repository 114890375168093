import { Fragment } from 'react';
import {
  Center,
  Flex,
  VStack,
  Text,
  ToastProps as ChakraToastProps,
  Grid,
  GridItem,
  ListItem,
  UnorderedList,
  Box,
} from '@chakra-ui/react';
import { Icon, type IconName } from '@/components/Icon';
import {
  ErrorUnprocessableResponse,
  ErrorValidationResponse,
  GeneralError,
  UnprocessableError,
  ValidationError,
} from '@/lib/api/schema';
import { __DEV__ } from '@/constants/env';

type ToastProps = ChakraToastProps & {
  errorResponse?:
    | ErrorValidationResponse
    | ErrorUnprocessableResponse
    | GeneralError;
};

export function Toast({
  status = 'error',
  title,
  description,
  errorResponse,
  onClose,
}: ToastProps) {
  const { iconName, colorScheme } = {
    info: {},
    loading: {},
    warning: {},
    error: { colorScheme: 'red', iconName: 'attention' as IconName },
    success: { colorScheme: 'green', iconName: 'checkCircle' as IconName },
    progress: { colorScheme: 'blue', iconName: 'sync' as IconName },
  }[status];

  console.log('toast error data', errorResponse);
  // TODO: 行儀悪い書き方。レスポンスメッセージの標準化が終わり次第リファクタ・修正
  // description = errorResponse?.message;

  function renderUnprocessableErrors(errors?: UnprocessableError[]) {
    return errors?.map(({ code, devMessage, userMessage }, i) => (
      <Box key={i}>
        {__DEV__ && <Text>{code}</Text>}
        {__DEV__ && <Text>{devMessage}</Text>}
        <Text>{userMessage}</Text>
      </Box>
    ));
  }

  function renderValidationErrors(errors: ValidationError[]) {
    return (
      <Grid templateColumns="auto 1fr" columnGap={3}>
        {errors.map(({ field, name, messages }) => (
          <Fragment key={field}>
            <GridItem>
              <Text>{name}</Text>
            </GridItem>
            <GridItem>
              <UnorderedList>
                {messages.map((message, i) => (
                  <ListItem key={i}>
                    <Text>{message}</Text>
                  </ListItem>
                ))}
              </UnorderedList>
            </GridItem>
          </Fragment>
        ))}
      </Grid>
    );
  }

  const elErrorDetail = (() => {
    switch (errorResponse?.status) {
      case 422:
        return renderUnprocessableErrors(
          (errorResponse as ErrorUnprocessableResponse).errors,
        );
      case 400:
        return renderValidationErrors(
          (errorResponse as ErrorValidationResponse).errors,
        );
      default:
        return <Text p={0}>{errorResponse?.message}</Text>;
    }
  })();

  return (
    <Flex
      align="start"
      gap={2}
      w={720}
      maxW={720}
      px={4}
      py={3}
      mt={1}
      ml={-100}
      bgColor={`${colorScheme}.100`}
      borderColor={`${colorScheme}.500`}
      borderWidth={1}
      borderRadius={4}
      wordBreak="break-word"
    >
      <Center h={5}>
        <Icon name={iconName!} color={`${colorScheme}.500`} size="sm" />
      </Center>
      <VStack w="full" align="start" gap={1} p={0}>
        {title && (
          <Text p="0" lineHeight={5} fontWeight="bold">
            {title}
          </Text>
        )}
        {description && <Text p={0}>{description}</Text>}
        {elErrorDetail}
      </VStack>
      <Center onClick={onClose} px={0} minW={4} h={5}>
        <Icon name="close" color={`${colorScheme}.500`} size="sm" />
      </Center>
    </Flex>
  );
}
